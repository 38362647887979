.loading {
  background-color: rgba($color: #202020, $alpha: 0.7);
  width: 100%;
  height: 100vh;
  position: fixed;
  top: 0;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  &__spinner {
    width: 60px !important;
    height: 60px !important;
    display: block !important;
  }
  &__mensaje {
    text-align: center;
    font-size: 20px;
    color: white;
    font-weight: 400;
    margin: 15px 0;
  }
}
