@import './variables.scss';

.tutores {
  &__cantidad-alumnos {
    width: 27px;
    height: 27px;
    border-radius: 100%;
    color: #000000;
    background-color: #d9d9d9;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 20px;
    font-weight: 600;
  }
  &__fila-tutor {
    cursor: pointer;
  }
  &__fila-tutor:hover {
    color: $primary;
  }
}
