@import 'variables.scss';

.nivel {
  &__icono {
    font-size: 30px !important;
    cursor: pointer;
    color: $secondary;
    margin: 0 10px;
  }
  &__icono-disabled {
    font-size: 30px !important;
    cursor: pointer;
    color: $secondary;
    margin: 0 10px;
    opacity: 50%;
  }
  &__foto {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px;
  }
  &__foto-disabled {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px;
    opacity: 50%;
  }
  &__foto-lista {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
  }
  &__foto-lista-disabled {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    margin: 0;
    opacity: 50%;
  }
  &__foto-disabled {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin: 20px;
    opacity: 50%;
  }
}
