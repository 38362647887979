.estudiantes {
  &__foto {
    width: 50px;
    height: 50px;
    border-radius: 50%;
  }
  &__accion {
    cursor: pointer;
  }
}
