.navmenu {
  color: white;
}

@media (min-width: 992px) {
  .navmenu {
    &__nav-items {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }
}
