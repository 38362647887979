.footer {
  border-top: solid 1px rgba(0, 0, 0, 0.25);
  width: 95%;
  margin: 0 auto;
  margin-top: 12px;
  font-size: 13px;
  &__texto {
    text-align: center;
    margin: 10px 0;
  }
}
