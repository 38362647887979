.detalle-estudiante {
  &__avatar {
    width: 250px;
    height: 250px;
    border-radius: 7px;
  }
  &__item {
    font-weight: bold;
    font-size: 18px;
    margin: 20px 0;
  }
}
