.acceso {
  &__logo-escuela {
    width: 350px;
  }
  &__contenedor-tarjeta {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  &__foto {
    border-radius: 100%;
    width: 180px;
    height: 180px;
    margin: 0 auto !important;
  }
  &__estado {
    font-weight: 500;
    width: 75%;
    margin: 5px auto;
    text-align: center;
    padding: 4px 0;
  }
  &__estado--entrada {
    background-color: green;
    color: white;
    font-weight: 500;
    width: 75%;
    margin: 5px auto;
    padding: 5px 0;
    text-align: center;
  }
  &__estado--salida {
    background-color: red;
    color: white;
    font-weight: 500;
    width: 75%;
    margin: 5px auto;
    padding: 5px 0;
    text-align: center;
  }
}

//i Queries para texto e imagenes
@media (max-width: 545px) {
  .acceso {
    &__tarjeta {
      width: 99%;
      overflow-x: visible;
    }
    &__logo-escuela {
      width: 300px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 539px) {
  .acceso {
    &__tarjeta {
      width: 49%;
      overflow-x: visible;
    }
    &__logo-escuela {
      width: 300px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .acceso {
    &__tarjeta {
      width: 49%;
      overflow-x: visible;
    }
    &__logo-escuela {
      width: 320px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .acceso {
    &__contenedor-tarjeta {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    &__foto {
      border-radius: 100%;
      width: 110px;
      height: 110px;
      margin: 0 auto !important;
    }
    &__tarjeta {
      width: 24%;
      overflow-x: visible;
    }
    &__titulo-tarjeta {
      font-size: 18px;
    }
    &__nombre-tarjeta {
      font-size: 16px;
    }
    &__logo-escuela {
      width: 320px;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .acceso {
    &__contenedor-tarjeta {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    &__foto {
      border-radius: 100%;
      width: 160px;
      height: 160px;
      margin: 0 auto !important;
    }
    &__titulo-tarjeta {
      font-size: 22px;
    }
    &__nombre-tarjeta {
      font-size: 20px;
    }
    &__tarjeta {
      width: 24%;
      overflow-x: visible;
    }
    &__logo-escuela {
      width: 380px;
    }
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .acceso {
    &__contenedor-tarjeta {
      display: flex;
      align-items: center;
      justify-content: space-around;
      flex-wrap: wrap;
    }
    &__foto {
      border-radius: 100%;
      width: 160px;
      height: 160px;
      margin: 0 auto !important;
    }
    &__tarjeta {
      width: 24%;
      overflow-x: visible;
    }
    &__titulo-tarjeta {
      font-size: 22px;
    }
    &__nombre-tarjeta {
      font-size: 20px;
    }
    &__logo-escuela {
      width: 400px;
    }
  }
}
