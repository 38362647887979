@import '../variables.scss';

.item-acceso {
  margin: 20px;
  border: solid 2px #000000 !important;
  cursor: pointer;
  &__contenedor {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding: 30px;
  }
  &__imagen {
    width: 128px;
    height: 128px;
  }
  &__titulo {
    margin: 10px 30px;
    color: $primary;
  }
}

a {
  text-decoration: none !important;
}

//i Queries para texto e imagenes
@media (max-width: 575px) {
  .item-acceso {
    &__imagen {
      width: 60px;
      height: 60px;
    }
    &__titulo {
      margin: 5px 15px;
      font-size: 20px;
    }
  }
}

// Small devices (landscape phones, 576px and up)
@media (min-width: 576px) {
  .item-acceso {
    &__contenedor {
      flex-direction: column;
    }
    &__imagen {
      width: 50px;
      height: 50px;
    }
    &__titulo {
      margin: 5px 15px;
      font-size: 18px;
    }
  }
}

// Medium devices (tablets, 768px and up)
@media (min-width: 768px) {
  .item-acceso {
    &__contenedor {
      flex-direction: column;
    }
    &__imagen {
      width: 80px;
      height: 80px;
    }
    &__titulo {
      margin: 15px;
      font-size: 22px;
    }
  }
}

// Large devices (desktops, 992px and up)
@media (min-width: 992px) {
  .item-acceso {
    &__contenedor {
      flex-direction: column;
    }
    &__imagen {
      width: 80px;
      height: 80px;
    }
    &__titulo {
      margin: 15px;
      font-size: 22px;
    }
  }
}

// X-Large devices (large desktops, 1200px and up)
@media (min-width: 1200px) {
  .item-acceso {
    &__contenedor {
      flex-direction: column;
    }
    &__imagen {
      width: 128px;
      height: 128px;
    }
    &__titulo {
      margin: 10px 30px;
      font-size: 28px;
    }
  }
}

// XX-Large devices (larger desktops, 1400px and up)
@media (min-width: 1400px) {
  .item-acceso {
    &__contenedor {
      flex-direction: column;
    }
    &__imagen {
      width: 128px;
      height: 128px;
    }
    &__titulo {
      margin: 10px 30px;
      font-size: 28px;
    }
  }
}
