.login {
  height: calc(90vh - 20px);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  box-sizing: border-box;
  &__logotipo {
    display: block;
    margin: 0 auto;
    margin-bottom: 40px;
    width: 90%;
    object-fit: cover;
  }
  &__contenedor-formulario {
    overflow: hidden !important;
  }
}
