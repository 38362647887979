.monitoreo {
  &__subtitulo-cantidad {
    font-weight: bold;
  }
  &__nivel {
    border: solid 1px #764a98 !important;
    cursor: pointer;
  }
  &__nivel-primaria {
    border: solid 1px #76b82a !important;
    cursor: pointer;
  }
  &__nivel-secundaria {
    border: solid 1px #764a98 !important;
    cursor: pointer;
  }
  &__nivel-preparatoria {
    border: solid 1px #e04512 !important;
    cursor: pointer;
  }
}
